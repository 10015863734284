"use client";

import MainMenu from "@/app/[locale]/components/common/MainMenu";
import SidebarPanel from "@/app/[locale]/components/common/sidebar-panel";
import LoginSignupModal from "@/app/[locale]/components/common/login-signup-modal";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import { signOut, useSession } from "next-auth/react";
import { DropdownMenu } from "@radix-ui/themes";
import { useRouter } from "next/navigation";
import LanguageSwitcher from "../LanguageSwitcher";
import { IoSearch } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { cn } from "@/lib/utils";
const Header = props => {
  const t = useTranslations("header");
  const router = useRouter();
  const {
    data: session
  } = useSession();

  // const [navbar, setNavbar] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [ref, setRef] = useState<string>("");

  // const changeBackground = () => {
  //   if (window.scrollY >= 10) {
  //     setNavbar(true);
  //   } else {
  //     setNavbar(false);
  //   }
  // };

  useEffect(() => {
    setMounted(true);
    const handleScroll = () => {
      setIsSticky(window.scrollY > 100);
      // changeBackground();
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function gotoProperty() {
    if (!ref?.startsWith("MB")) {
      router.push(`/imovel/MB${ref}`);
    } else {
      router.push(`/imovel/${ref}`);
    }
  }
  return <>
      <header className={`top-0 header-nav nav-homepage-style main-menu ${mounted && isSticky ? "sticky slideInDown animated" : "!bg-transparent"} md:block hidden`} style={{
      ...props.style,
      zIndex: 999
    }}>
        <nav className="posr">
          <div className="container posr menu_bdrt1">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="logos mr40">
                    <Link className="header-logo logo1" href="/" data-sentry-element="Link" data-sentry-source-file="Header.tsx">
                      <Image width={125} height={50.5} src="/images/mbras-hb.png" alt="Header Logo" data-sentry-element="Image" data-sentry-source-file="Header.tsx" />
                    </Link>
                    <Link className="header-logo logo2" href="/" data-sentry-element="Link" data-sentry-source-file="Header.tsx">
                      <Image width={125} height={50.5} src="/images/mbras-hb-dark.png" alt="Header Logo" data-sentry-element="Image" data-sentry-source-file="Header.tsx" />
                    </Link>
                  </div>
                  {/* End Logo */}

                  <MainMenu data-sentry-element="MainMenu" data-sentry-source-file="Header.tsx" />
                  {/* End Main Menu */}
                </div>
              </div>
              {/* End .col-auto */}

              <div className="col-auto">
                <div className="d-flex align-items-center">
                  <span className="relative">
                    <IoSearch className="absolute left-2 top-1/2 -translate-y-1/2" data-sentry-element="IoSearch" data-sentry-source-file="Header.tsx" />
                    <input className={cn("form-control rounded-none bg-transparent border-t-0 border-l-0 border-r-0 border-b-black p-3 !pl-8 !pr-8 h-6 w-40 focus:rounded-xl", {
                    "text-black": isSticky,
                    "text-white": !isSticky,
                    "border-b-black": isSticky,
                    "border-b-white": !isSticky,
                    "border-black": isSticky,
                    "border-white": isSticky,
                    "placeholder:text-gray-400": isSticky,
                    "placeholder:text-gray-300": !isSticky
                  })} placeholder="Ref do imóvel" value={ref} onChange={e => setRef(e.target.value)} onKeyDown={e => {
                    if (e.key === "Enter") {
                      gotoProperty();
                    }
                  }} />
                    <button className="ml-2 ud-btn btn-apple w-6 h-6 rounded-full right-1 top-1/2 -translate-y-1/2 p-0 flex items-center justify-center absolute hover:translate-x-1" style={{
                    background: "#21293A"
                  }} onClick={e => {
                    gotoProperty();
                  }}>
                      <IoIosArrowForward className="w-full" data-sentry-element="IoIosArrowForward" data-sentry-source-file="Header.tsx" />
                    </button>
                  </span>
                  <LanguageSwitcher data-sentry-element="LanguageSwitcher" data-sentry-source-file="Header.tsx" />
                  <span className="d-none d-xl-block bdrs60" style={{
                  background: "white",
                  padding: "0 1rem",
                  height: "3rem",
                  display: "flex",
                  alignItems: "center"
                }}>
                    {session ? <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                          <div className="login-info d-flex align-items-center h-full">
                            <i className="far fa-user-circle fz16 me-2 d-none" style={{
                          color: "black"
                        }} />
                            <p style={{
                          fontWeight: 600,
                          color: "black"
                        }}>
                              {session?.user?.name}
                            </p>
                            <DropdownMenu.TriggerIcon style={{
                          marginLeft: "0.5rem",
                          color: "black",
                          height: "100%"
                        }} />
                          </div>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content color="gray" className="p-2" style={{
                      zIndex: 9999,
                      width: "15rem",
                      fontWeight: 700
                    }}>
                          {!session && <Link href="/login">
                              <DropdownMenu.Item>
                                {t("buttons.login")}
                              </DropdownMenu.Item>
                            </Link>}
                          {session && <>
                              <Link href="/profile">
                                <DropdownMenu.Item>
                                  <i className="far fa-user-circle fz16 me-2" />
                                  Meu perfil
                                </DropdownMenu.Item>
                              </Link>

                              <DropdownMenu.Separator />
                              <DropdownMenu.Item onClick={() => {
                          signOut();
                        }}>
                                {t("buttons.logout")}
                              </DropdownMenu.Item>
                            </>}
                        </DropdownMenu.Content>
                      </DropdownMenu.Root> : <div className="h-full flex items-center font-bold">
                        <Link href="/login">{t("buttons.login")}</Link>
                      </div>}
                  </span>
                  <Link className="ud-btn btn-apple add-property bdrs60 mx-2 mx-xl-4 border-0" href="/anuncie" data-sentry-element="Link" data-sentry-source-file="Header.tsx">
                    {t("buttons.advertiseYourProperty")}
                    <i className="fal fa-arrow-right-long" />
                  </Link>

                  {/* <a
                    className="sidemenu-btn filter-btn-right"
                    href="#"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#SidebarPanel"
                    // aria-controls="SidebarPanel"
                   >
                    <Image
                      width={25}
                      height={9}
                      className="img-1"
                      src="/images/icon/nav-icon-white.svg"
                      alt="humberger menu"
                    />
                      <Image
                      width={25}
                      height={9}
                      className="img-2"
                      src="/images/icon/nav-icon-dark.svg"
                      alt="humberger menu"
                    />
                   </a> */}
                </div>
              </div>
              {/* End .col-auto */}
            </div>
            {/* End .row */}
          </div>
        </nav>
      </header>
      {/* End Header */}

      {/* Signup Modal */}
      <div className="signup-modal">
        <div className="modal fade" id="loginSignupModal" tabIndex={-1} aria-labelledby="loginSignupModalLabel" aria-hidden="true">
          <div className="modal-dialog  modal-dialog-scrollable modal-dialog-centered">
            <LoginSignupModal data-sentry-element="LoginSignupModal" data-sentry-source-file="Header.tsx" />
          </div>
        </div>
      </div>
      {/* End Signup Modal */}

      {/* DesktopSidebarMenu */}
      <div className="offcanvas offcanvas-end" tabIndex={-1} id="SidebarPanel" aria-labelledby="SidebarPanelLabel">
        <SidebarPanel data-sentry-element="SidebarPanel" data-sentry-source-file="Header.tsx" />
      </div>
      {/* Sidebar Panel End */}
    </>;
};
export default Header;